import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import Image from 'gatsby-image'
import './bioBadge.scss'

function BioBadge() {
    return (
        <StaticQuery
            query={bioBadgeQuery}
            render={data => {
                const {author} = data.site.siteMetadata
                return (
                    <div className='bio-badge-content'>
                        <div className='bio-badge-name'>AZD</div>
                        <Image
                            className='ui circular image bio-badge-image'
                            fixed={data.avatar.childImageSharp.fixed}
                            alt={author}
                        />
                    </div>
                )
            }}
        />
    )
}

const bioBadgeQuery = graphql`
  query BioBadgeQuery {
    avatar: file(absolutePath: { regex: "/me_1.jpg/" }) {
      childImageSharp {
        fixed(
            width: 67,
            height: 67,
            quality: 100
            ){
            ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
      }
    }
  }
`

export default BioBadge
