import React, {Component} from 'react'
import './searchInput.scss'

export default class SearchInput extends Component {
    render() {

        const deviceType = this.props.deviceType;
        const className = 'ui search icon input ' + deviceType;

        if (typeof window !== `undefined`) {
            return (
                <div className={className}>
                    <input className="search-blog-list-input prompt"
                           type="search"
                           placeholder='Recherche'
                           onChange={window.handleSearchPostInputChange}/>
                    <i className="search icon"/>
                </div>
            )
        } else {
            return null
        }
    }
}
