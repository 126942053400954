import PropTypes from 'prop-types'
import React from 'react'
import './header.scss'
import Navbar from "../Navbar/Navbar";
import NavbarBottom from "../NavbarBottom/NavbarBottom";

// const Header = () => {
//     return (
//         <div className='header'>
//             <div className='nav'>
//                 <Link to="/"><h1>La patisserie</h1></Link>
//                 <div>
//                     <Link to="/blog-inspiration/"><h3>Inspirations</h3></Link>
//                 </div>
//                     <Link to="/wish-list/"><h2>Wish list</h2></Link>
//             </div>
//         </div>
//     )
// }

const Header = () => {
    return (
        <div className='header'>
            <Navbar/>
            <NavbarBottom/>
        </div>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: '',
}

export default Header
