import React, {Component} from 'react'
import {Link} from 'gatsby'
import {Icon, Menu, Responsive} from 'semantic-ui-react'

export default class NavbarBottom extends Component {

    render() {

        return (
            <Responsive as={Menu} icon='labeled' fluid widths={4} fixed='bottom'
                        maxWidth={Responsive.onlyTablet.maxWidth}>
                <Menu.Item as={Link} activeClassName='active' to='/'>
                    <Icon name='home'/>
                    Réalisations
                </Menu.Item>
                <Menu.Item as={Link} activeClassName='active' to='blog-inspiration'>
                    <Icon name='file alternate'/>
                    Inspirations
                </Menu.Item>
                <Menu.Item as={Link} activeClassName='active' to='wish-list'>
                    <Icon name='lemon outline'/>
                    Wish list
                </Menu.Item>
                <Menu.Item as={Link} activeClassName='active' to='contact'>
                    <Icon name='paper plane'/>
                    Contact
                </Menu.Item>
            </Responsive>
        )
    }
}
