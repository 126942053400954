import React, {Component} from 'react'
import {Link} from 'gatsby'
import {Container, Icon, Menu, Responsive} from 'semantic-ui-react'
import BioBadge from "../BioBadge/BioBadge";
import SearchInput from "../SearchInput/SearchInput";

export default class Navbar extends Component {
    state = {
        menuFixed: false,
    }

    handleOnUpdate = (e, {width}) => this.setState({width})

    render() {
        const {width} = this.state
        let menuWidths;
        if (width >= Responsive.onlyTablet.maxWidth) {
            menuWidths = null;
        } else if (width >= Responsive.onlyMobile.maxWidth) {
            menuWidths = 2;
        } else {
            menuWidths = 2;
        }

        return (
            <Responsive as={Menu}
                        borderless
                        fixed={'top'}
                        className='fixed-menu-style'
                        fireOnMount
                        onUpdate={this.handleOnUpdate}
                        widths={menuWidths}>
                <Container text>
                    <Menu.Item header as={Link} to='/'>
                        <BioBadge/>
                        <Icon name='birthday cake'/>
                        PÂTISSERIE
                    </Menu.Item>
                    <Responsive as={Menu.Menu} position='right' {...Responsive.onlyComputer}>
                        <Menu.Item as={Link} activeClassName='active' to='blog-inspiration'>INSPIRATIONS</Menu.Item>
                        <Menu.Item as={Link} activeClassName='active' to='wish-list'>WISH LIST</Menu.Item>
                        <Menu.Item as={Link} activeClassName='active' to='contact'>CONTACT</Menu.Item>
                        <Menu.Item><SearchInput/></Menu.Item>
                    </Responsive>
                    <Responsive as={Menu.Item} {...Responsive.onlyTablet} >
                        <SearchInput deviceType='tablet'/>
                    </Responsive>
                    <Responsive as={Menu.Item} {...Responsive.onlyMobile} >
                        <SearchInput deviceType='mobile'/>
                    </Responsive>
                </Container>
            </Responsive>
        )
    }
}
